@import url(https://fonts.googleapis.com/css?family=Patua+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  color: #1b1d1c;
  font-size: 0.9rem;
  
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* 
  For light mode, secondary is darker than primary.
  For dark mode, secondary is lighter than primary.
  */
  --primary: #678063;
  --secondary: #565f54;
  --primary-dark-mode: #d6e7d6;
  --secondary-dark-mode: #eef5ee;
}

.font-weight-500 {
  font-weight: 500;
}

h1, h2, h3, h4 {
  font-family: 'Patua One', cursive;
}

/* 'About' card */
#about {
  padding-top: 75px;
  padding-bottom: 10px;
  margin-top: -75px;
}
.card {
  background-color: transparent;
}
.card-link {
  position: relative;
}

/* Sections */
.section-title {
  text-transform: lowercase;
  padding-top: 30px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  color: var(--primary);
  border-bottom: thin solid;
}
.text-light .section-title {
  color: var(--primary-dark-mode);;
}

/* Buttons and links */
.btn-sm.round {
  padding: 0.3rem 0.8rem;
  border-radius: 5rem;
}

.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
}
.text-light .btn-primary {
  background-color: var(--primary-dark-mode);
  border-color: var(--primary-dark-mode);
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active {
  background-color: var(--secondary);
  border-color: var(--secondary);
}
.text-light .btn-primary:hover,
.text-light .btn-primary:focus,
.text-light .btn-primary:not(:disabled):not(.disabled):active {
  background-color: var(--secondary-dark-mode);
  border-color: var(--secondary-dark-mode);
}
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.custom-control-input {
  outline-color: var(--secondary);
  box-shadow: 0 0 0 .2rem rgba(103, 128, 99, 0.5);
}
.text-light .btn-primary:focus,
.text-light .btn-primary:not(:disabled):not(.disabled):active:focus {
  outline-color: var(--secondary-dark-mode);
  box-shadow: 0 0 0 .2rem rgba(103, 128, 99, 0.5);
}

a,
.btn-link {
  color: var(--primary);
}
.text-light a,
.text-light .btn-link {
  color: var(--primary-dark-mode);
}
a:focus,
.btn-link:focus {
  box-shadow: 0 0 0 0
}
a:hover,
.btn-link:hover,
a:focus,
.btn-link:focus {
  color: var(--secondary);
}
.text-light a:hover,
.text-light .btn-link:hover,
.text-light a:focus,
.text-light .btn-link:focus {
  color: var(--secondary-dark-mode);
}

/* Dark/light mode toggle */
#dark-mode-toggle {
  position: relative;
  z-index: 2000; /* above sticky header */
}
.mode-toggle .active-icon,
.mode-toggle:hover .inactive-icon {
  display: inline;
}
.mode-toggle .inactive-icon,
.mode-toggle:hover .active-icon {
  display: none;
}
.work-container {
    position: relative;
    text-align: center;
    margin: 0px 5px 10px 5px;
}

.medium {
    /* visibility: hidden; */
    position: absolute;
    width: 100%;
    height: 20px;
    bottom: 0;
    padding-top: 9px;
    color: white;
    background-color: rgba(0, 0, 0, 0.4);
    font-size: x-small;
}

#artworks {
    /* Prevent vertical gaps */
    line-height: 0;
        
    -webkit-column-count: 4;
    -webkit-column-gap:   0px;
    -moz-column-count:    4;
    -moz-column-gap:      0px;
    column-count:         4;
    grid-column-gap:           0px;
    column-gap:           0px;  
}

#artworks img {
    width: 100% !important;
    height: auto !important;
}

@media (max-width: 1200px) {
    #artworks {
    column-count:         4;
    }
}
@media (max-width: 1000px) {
    #artworks {
    column-count:         3;
    }
}
@media (max-width: 800px) {
    #artworks {
    column-count:         2;
    }
}
@media (max-width: 400px) {
    #artworks {
    column-count:         1;
    }
}
