.work-container {
    position: relative;
    text-align: center;
    margin: 0px 5px 10px 5px;
}

.medium {
    /* visibility: hidden; */
    position: absolute;
    width: 100%;
    height: 20px;
    bottom: 0;
    padding-top: 9px;
    color: white;
    background-color: rgba(0, 0, 0, 0.4);
    font-size: x-small;
}

#artworks {
    /* Prevent vertical gaps */
    line-height: 0;
        
    -webkit-column-count: 4;
    -webkit-column-gap:   0px;
    -moz-column-count:    4;
    -moz-column-gap:      0px;
    column-count:         4;
    column-gap:           0px;  
}

#artworks img {
    width: 100% !important;
    height: auto !important;
}

@media (max-width: 1200px) {
    #artworks {
    -moz-column-count:    4;
    -webkit-column-count: 4;
    column-count:         4;
    }
}
@media (max-width: 1000px) {
    #artworks {
    -moz-column-count:    3;
    -webkit-column-count: 3;
    column-count:         3;
    }
}
@media (max-width: 800px) {
    #artworks {
    -moz-column-count:    2;
    -webkit-column-count: 2;
    column-count:         2;
    }
}
@media (max-width: 400px) {
    #artworks {
    -moz-column-count:    1;
    -webkit-column-count: 1;
    column-count:         1;
    }
}